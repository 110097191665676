import * as React from "react";
import { graphql } from "gatsby";
import TalkTile from "../components/talk-tile";
import ConferenceAdTile from "../components/conference-ad-tile";
import { StaticImage } from "gatsby-plugin-image";
import { SEO } from "../components/seo";
import conferenceAds from "../../private-data/conference-ads/conference-ads.json";
import GatheringTile from "../components/gathering-tile";
import { useState, useEffect } from "react";
import { ConferenceAd } from "../types/conference-ad";
import BackgroundCarousel from "../components/background-carousel";
import { useScrollFade } from "../hooks/use-scroll-fade";

export const Head = () => <SEO title="async TechConnection" />;

const IndexPage = ({
  data: {
    allEventsJson: { nodes: allEventsNodes },
    allMarkdownRemark: { edges },
  },
}) => {
  const [displayedPosts, setDisplayedPosts] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const postsPerPage = 12;
  const carouselOpacity = useScrollFade();

  // Filter and prepare all posts
  const allPosts = edges
    .filter((edge) => !!edge.node.frontmatter.date)
    .map((edge) => ({
      ...edge,
      node: { ...edge.node },
    }));

  // Load more posts when scrolling
  const loadMorePosts = () => {
    const nextPosts = allPosts.slice(0, page * postsPerPage);
    setDisplayedPosts(nextPosts);
    setPage(page + 1);
  };

  // Initial load
  useEffect(() => {
    loadMorePosts();
  }, []);

  // Infinite scroll handler
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
          document.documentElement.scrollHeight - 1000 &&
        displayedPosts.length < allPosts.length
      ) {
        loadMorePosts();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [displayedPosts, page]);

  const posts = displayedPosts.map((edge) => (
    <TalkTile key={edge.node.id} post={edge.node} eventPageMode={false} />
  ));

  const conferences = conferenceAds.map((conferenceAd: ConferenceAd) => (
    <ConferenceAdTile key={conferenceAd.title} {...conferenceAd} />
  ));

  const gatherings = allEventsNodes.map((event) => (
    <GatheringTile key={event.slug} {...event} />
  ));

  return (
    <>
      <div className="fixed h-full w-full gradient bg-gradient-to-tr from-slate-900 from-70% to-slate-800 -z-10">
        &nbsp;
      </div>
      <div className="leading-normal tracking-normal text-white bg-transparent">
        <div
          id="background-carousel"
          className="fixed w-full h-96 min-h-[calc(100vh-40px)] top-0 -z-10"
          style={{ opacity: carouselOpacity }}
        >
          <BackgroundCarousel />
          <div
            className="absolute bottom-0 w-full h-40 bg-gradient-to-b from-transparent to-slate-900"
            style={{ opacity: carouselOpacity }}
          ></div>
        </div>
        <nav
          id="header"
          className="fixed w-full z-30 top-0 text-white bg-black/30"
        >
          <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-4">
            <div className="pl-4 flex items-center">
              <a
                className="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
                href="/"
              >
                <code>async</code>.TechConnection
              </a>
            </div>
          </div>
        </nav>
        <div className="pt-24 mb-24">
          <div
            id="hero"
            className="container px-3 mx-auto h-96 mb-8 text-center grid"
          >
            <div className="place-self-center max-w-[90vw] relative">
              <StaticImage
                className="mx-auto max-w-[90vw] md:max-w-full"
                layout="fixed"
                height={240}
                src="../../static/tech-connection-logo.svg"
                alt="TechConnection Logo"
              />
            </div>
          </div>
          {conferences && conferences.length > 0 && (
            <div className="container mx-auto px-4 mb-8">
              <h2 className="text-2xl font-bold mb-2">Upcoming Conferences</h2>
              <div className="flex overflow-x-auto gap-4">{conferences}</div>
            </div>
          )}
          <div className="container mx-auto px-4 mb-8">
            <h2 className="text-2xl font-bold mb-2">Our Gatherings</h2>
            <div className="flex overflow-x-auto gap-4">{gatherings}</div>
          </div>
          <div className="container mx-auto px-4">
            <h2 className="text-2xl font-bold mb-2">All videos</h2>
            <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 grid-flow-row">
              {posts}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allEventsJson {
      nodes {
        slug
        name
        logo {
          childImageSharp {
            gatsbyImageData(quality: 99)
          }
          publicURL
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date, frontmatter___title] }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          thumbnailImage {
            childImageSharp {
              gatsbyImageData(quality: 99)
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            author
            tags
            video
            conference
            edition
          }
        }
      }
    }
  }
`;
