import { useState, useEffect } from "react";

interface ScrollFadeOptions {
  fadeStart?: number;
  fadeEndPercentage?: number;
}

export const useScrollFade = ({
  fadeStart = 0.1,
  fadeEndPercentage = 0.4,
}: ScrollFadeOptions = {}) => {
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    let ticking = false;
    let animationFrameId: number;

    const updateOpacity = (scrollPos: number) => {
      const fadeEnd = window.innerHeight * fadeEndPercentage;

      if (scrollPos <= fadeStart) {
        setOpacity(1);
      } else if (scrollPos >= fadeEnd) {
        setOpacity(0);
      } else {
        const newOpacity = 1 - scrollPos / fadeEnd;
        setOpacity(Math.max(0, Math.min(1, newOpacity)));
      }
      ticking = false;
    };

    const handleScroll = () => {
      if (!ticking) {
        ticking = true;
        updateOpacity(window.scrollY);
        // animationFrameId = window.requestAnimationFrame(() => {
        //   updateOpacity(window.scrollY);
        // });
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (animationFrameId) {
        window.cancelAnimationFrame(animationFrameId);
      }
    };
  }, [fadeStart, fadeEndPercentage]);

  return opacity;
};
