import React, { useState, useEffect } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const BackgroundCarousel: React.FC = () => {
  const [currentImage, setCurrentImage] = useState(0);

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativePath: { regex: "hero-carousel/home/.+/" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(
              quality: 90
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              layout: FULL_WIDTH
            )
          }
          name
        }
      }
    }
  `);

  const carouselImages = data.allFile.nodes;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((current) => (current + 1) % carouselImages.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [carouselImages.length]);

  return (
    <div className="absolute inset-0 w-full h-full">
      {carouselImages.map((image, index) => {
        const gatsbyImage = getImage(image);
        return gatsbyImage ? (
          <div
            key={image.name}
            className={`absolute inset-0 transition-opacity duration-1000 ${
              currentImage === index ? "opacity-100" : "opacity-0"
            }`}
          >
            <GatsbyImage
              image={gatsbyImage}
              alt={`Background ${index + 1}`}
              className="w-full h-full"
              objectFit="cover"
            />
          </div>
        ) : null;
      })}
      <div className="absolute inset-0 bg-black/30" />
    </div>
  );
};

export default BackgroundCarousel;
