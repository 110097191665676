import { ConferenceAd } from "../types/conference-ad";
import * as React from "react";

function ConferenceAdTile(conferenceAd: ConferenceAd) {
  return (
    <div
      key={conferenceAd.slug}
      className="flex-shrink-0 rounded-lg overflow-clip"
    >
      <a href={conferenceAd.link} target="_blank">
        <img
          className="max-w-[80vw] sm:max-w-xs mx-auto"
          src={`/conference-ads/${conferenceAd.image}`}
          alt={conferenceAd.title}
        />
      </a>
    </div>
  );
}

export default ConferenceAdTile;
